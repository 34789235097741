//TODO canlıya alırken "use client" kaldırılacak ve import "./rsaeyeni.css" eklenecek

import "./rsaeyeni.css"
import {
  Button ,
  Paper ,
  TableRow,
  TableHead ,
  TableContainer ,
  TableCell ,
  TableBody ,
  Table,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem, 
  InputLabel,
  FormControl,
  TablePagination
  }
  from "@mui/material";
import { useState  } from "react";

//bilimsel gösterime çeviren fonksiyon
function toScientificNotation(num , precision = 6) {
  return num.toExponential(precision);
}

//TODO canlıya alırken home bileşeninin adı Rseayeni olarak değiştirilecek 
export default function Rseayeni() {
  //input dosyası değişkeni
  const [inputFile, setInputFile] = useState(null);

  //opsiyonel model seçiminde kullanılacak olan değişken
  const [modelFile, setModelFile] = useState(null);

  //test tiplerinde kullanılacak olan checkbox verilerinin array halinde virgülle ayrılmış olarak tutulacağı değişken
  const [checkboxes, setCheckboxes] = useState([]);
  const [response, setResponse] = useState(null);
  const [response2, setResponse2] = useState(null);

  //inputlar text olarak alınırsa kullanılacak olan değişken
  const [textAreaValue, setTextAreaValue] = useState("");

  //veritabanının seçilmesinde kullanılacak olan değişken
  const [selectDB, setSelectDB] = useState("KEGG")

  //alertlerin gösterilmesini yöneten fonksiyonlar
  const [inputfileAlert, setinputfileAlert] = useState(false)
  const [modelAlert, setmodelAlert] = useState(false)
  const [multitestAlert, setmultitestAlert] = useState(false)
  const [filetypeAlert, setfiletypeAlert] = useState(false)

  //inputlar yazılı olarak girilirken kelime sayısını sayacak olan state
  const [inputCount, setInputCount] = useState(0);

  //background elle yazılı olarak girilirse
  const [backgroundValue, setBackgroundValue] = useState("")
  
  //hazır model seçiminde kullanılacak olan değişken
  const [model, setModel] = useState("")
  const handleInputFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "text/plain" || file.name.endsWith(".csv"))) {
      setInputFile(file);
    } else {
      setfiletypeAlert(true)
      setTimeout(() => {
        setfiletypeAlert(false)
      }, 3000);
    }
  };
  
  
  const handleModelFileChange = (e) => {
    const file = e.target.files[0];
        if (file && (file.type === "text/plain" || file.name.endsWith(".csv"))) {
          setModelFile(file);
        }
    };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setCheckboxes((prev) => 
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  //seçili olan veritabanı değiştirildiğinde oluşacak event
  //değer girişi olmazsa varsayılan olarak KEGG veritabanına göre işlemler gerçekleştiril
  const handleSelectedDBChange = (e) =>{
    setSelectDB(e.target.value)
  }

  //-gem modellerinden biri seçilirse onu işleyecek olan event
  const handleModelChange = (e) =>{
    setModel(e.target.value)
  }

  //form sumbit edilirkenki işlem
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const formData2 = new FormData();

    //kullanıcı girdisi olan reaksiyonların olduğu inputfile
    if(inputFile){
      formData.append("input_rx_file", inputFile);
      formData.append("textarea_input", textAreaValue); // inputların text area olarak girilmesi durumunda

      formData2.append("input_rx", inputFile);
      formData2.append("textarea_input", textAreaValue); // inputların text area olarak girilmesi durumunda
    }
    else if (textAreaValue) {
      let wordSize = 0
      const textareaValue2 = textAreaValue.trim();
      // Count the number of words
      wordSize = textareaValue2.split(/\s+/).length;
      setInputCount(wordSize);
      const blob = new Blob([textareaValue2], { type: "text/plain" });
      formData.append("input_rx_file", blob, "input_rx_file.txt");
      formData2.append("input_rx", "input_rx_file.txt");
    }
    else{
      setinputfileAlert(true)
      setTimeout(() => {
        setinputfileAlert(false)
      }, 3000);
      return
    }

    //model kullanıcının kendi belirleyeceği reaksiyonlardan oluşuyorsa
    if (modelFile) {
      const blob = new Blob([modelFile], { type: "text/plain" });
      formData.append("model_rx_file", blob, "model_rx_file.txt");
      formData2.append("model_rx", blob, "model_rx_file.txt");

    }
    else if(backgroundValue){
      let wordSize = 0
      const textareaValue2 = backgroundValue.trim();
      // Count the number of words
      wordSize = textareaValue2.split(/\s+/).length;
      setInputCount(wordSize);
      const blob = new Blob([textareaValue2], { type: "text/plain" });
      formData.append("model_rx_file", blob, "model_rx_file.txt");
      formData2.append("model_rx", blob, "model_rx_file.txt");

    }
    // Model hazır modellerden biri olarak seçilirse gönderilecek değişken
    else if(model){
      formData.append("model", model);
      formData2.append("model_name", model); 

    }
    else{
      setmodelAlert(true)
      setTimeout(() => {
        setmodelAlert(false)
      }, 3000);
    };


    //test tiplerinin gönderileceği checkboxes
     // checkbox'ları JSON formatında ekliyoruz
     if (checkboxes.length !== 0) {
      formData.append("checkboxes", JSON.stringify(checkboxes)); // JSON string olarak gönderiyoruz
    } else {
      setmultitestAlert(true)
      setTimeout(() => {
        setmultitestAlert(false)
      }, 3000);
      return
    }

    formData.append("xref", selectDB); // Varsayılan olarak KEGG gelir
    formData2.append("xref", selectDB); // Varsayılan olarak KEGG gelir


   //TODO https://rseatool.com/uploadfile2/ canlıda da localde de bu şekilde bağlantı olacak
    try {
      const response = await fetch('https://rseatool.com/uploadfile2/', {
        method: 'POST',
        body: formData,
      }
    );

      const result = await response.json();
      setResponse(result);
    } catch (error) {
      console.error('Error:', error);
    }

    //input ve reaksiyon sayılarının doğru gelmesi için 2. api isteği
    try{
      const response = await fetch('https://rseatool.com/reac_count/' , {
        method : 'POST',
        body : formData2,
      }
    );
      const result = await response.json();
      setResponse2(result);
    }
    catch(err){
      console.log("Err : "+err)
    }
  };

  return (
    <div className="appCss">
      
      <Alert severity="warning" sx={{display : inputfileAlert ? 'block' : 'none'}}>inputfile gir</Alert>
      <Alert severity="warning" sx={{display : modelAlert ? 'block' : 'none'}}>bir model seç ve ya kendin background gir</Alert>
      <Alert severity="warning" sx={{display : multitestAlert ? 'block' : 'none'}}>En az bir multitest seçmelisiniz</Alert>
      <Alert severity="warning" sx={{display : filetypeAlert ? 'block' : 'none'}}>Lütfen geçerli bir TXT veya CSV dosyası seçin.</Alert>
     
      <div>
      <form onSubmit={handleSubmit}>
        <label>
          Input Reaction Dosyası (input_rx_file):
          TXT ve ya CSV olarak input
          <input type="file" onChange={handleInputFileChange} />
          <br />
          <br />
          <br />
          Yazılı olarak input
          <textarea
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            placeholder="Reaksiyon ID'lerini girin..."
          />
        </label>
        <br />
        <br />
        <br />
        <br />
        <label>
          Model Reaction File: (opsiyoneldir)
          <input type="file" onChange={handleModelFileChange} />
          <br />
          Yazılı olarak background
          <textarea
            value={backgroundValue}
            onChange={(e) => setBackgroundValue(e.target.value)}
            placeholder="Reaksiyon ID'lerini girin..."
          />
        </label>
        <br />
        <br />
        <br />
        <br />
      {/* multitesting checkboxes */}
      <div>
        <h2>Multiple Testing</h2>
        <FormControlLabel
          control={
            <Checkbox value="bonferroni" onChange={handleCheckboxChange} />
          }
          label="Bonferroni"
        />
        <br />
        <FormControlLabel
          control={<Checkbox value="sidak" onChange={handleCheckboxChange} />}
          label="Sidak"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox value="holm-sidak" onChange={handleCheckboxChange} />
          }
          label="holm sidak"
        />
        <br />
        <FormControlLabel
          control={<Checkbox value="holm" onChange={handleCheckboxChange} />}
          label="holm"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox value="simes-hochberg" onChange={handleCheckboxChange} />
          }
          label="simes-hochberg"
        />
        <br />
        <FormControlLabel
          control={<Checkbox value="hommel" onChange={handleCheckboxChange} />}
          label="hommel"
        />
        <br />
        <FormControlLabel
          control={<Checkbox value="fdr_bh" onChange={handleCheckboxChange} />}
          label="fdr_bh"
        />
        <br />
        <FormControlLabel
          control={<Checkbox value="fdr_by" onChange={handleCheckboxChange} />}
          label="fdr_by"
        />
        <br />
        <FormControlLabel
          control={<Checkbox value="fdr_tsbh" onChange={handleCheckboxChange} />}
          label="fdr_tsbh"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox value="fdr_tsbky" onChange={handleCheckboxChange} />
          }
          label="fdr_tsbky"
        />
      </div>

        <br />
        <br />
        <br />
      {/* selecting model options */}
      <div>
      <h3>Select Model</h3>
      <br />
      <FormControl sx={{width :'200px'}}>
        <InputLabel id="model-select-label">Model</InputLabel>
        <Select
          labelId="model-select-label"
          value={model}
          onChange={handleModelChange}
          label="Model"
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="Fruitfly-GEM">Fruitfly-GEM</MenuItem>
          <MenuItem value="Human-Gem">Human-Gem</MenuItem>
          <MenuItem value="Mouse-GEM">Mouse-GEM</MenuItem>
          <MenuItem value="Rat-GEM">Rat-GEM</MenuItem>
          <MenuItem value="Worm-GEM">Worm-GEM</MenuItem>
          <MenuItem value="Yeast-GEM">Yeast-GEM</MenuItem>
          <MenuItem value="Zebrafish-GEM">Zebrafish-GEM</MenuItem>
          <MenuItem value="Recon3D_VMH">Recon3D_VMH</MenuItem>
          <MenuItem value="Recon3D_BiGG">Recon3D_BİGG</MenuItem>
        </Select>
      </FormControl>
    </div>
        <br />
        <br />
        <br />
        {/* Select database options */}
        <div>
          <h3>Veritabanı Seç (KEGG, RHEA, REACTOME)</h3>
          <FormControl sx={{ width: '200px' }}>
            <InputLabel id="database-select-label">Veritabanı Seç</InputLabel>
            <Select
              labelId="database-select-label"
              value={selectDB}
              onChange={handleSelectedDBChange}
              label="Veritabanı Seç"
            >
              <MenuItem value="KEGG">KEGG</MenuItem>
              <MenuItem value="Reactome">REACTOME</MenuItem>
              <MenuItem value="RHEA">RHEA</MenuItem>
            </Select>
          </FormControl>
        </div>

        <br />
        <Button variant="contained" type="sumbit">Upload Files</Button> 

      </form>

      {response && response2 && (
        <div>
          <h3>Response:</h3>
          <DataTable jsonData={JSON.stringify(response, null, 2)} jsonData2={response2.counts}/>
        </div>
      )}
    </div>
    </div>
  );
}



const DataTable = ({ jsonData , jsonData2}) => {
  let parsedData = [];
  let columnNames = [];
  let unmatchedReactions = [];
  //tabloda number of matches'a tıklanınca hangilernin eşleştiğini gösterecek olan tablonun durumu
  const [showTable, setShowTable] = useState(false)

  //hangi elemanın eşleştiklerini göstereceği bilgisini tutan id değişkeni
  const [dialogId, setDialogId] = useState(null)

  //tabloda number of matches'a tıklanınca hangilernin eşleştiğini gösterecek olan dialogun
  //Durumunun değiştirilmesi
  const handleShowTable = (id) => {
    setShowTable(!showTable);
    setDialogId(id);
  }

   //eğer değişken true ise eşleşenler tablosu gösterilecek , false ise eşleşmeyenler tablosu gösterilecek
   const [toggleUnmatchedNMatched , setToggleUnmatchedNMatched] = useState(true)

   const handleShowTableClose = () => {
     setShowTable(false); // Diyalog kapanınca state'i sıfırla
     setDialogId(null);
   };
 

  //Sonuçların csv olarak indirilmesi
  const handleDownloadCSV = (list) => {
    if (toggleUnmatchedNMatched) {
      const csvHeader = columnNames.map(col => `"${col}"`).join(";"); // Her sütunu çift tırnakla sar
      const csvRows = parsedData.map((row , rowIndex) => {
        const newRow = row.map(cell => {
          if (typeof cell === 'object' && cell !== null && cell.hasOwnProperty('length')) {
            // Eğer hücre bir diziye benzerse (dizi olup olmadığını kontrol ediyoruz)
            return `"${cell.join(",")}"`;
          }
          // Diğer veriler için de çift tırnakla sarma işlemi yap
          return `"${cell}"`;
        });
        return newRow.join(";");
      });

      const csvData = csvHeader + "\n" + csvRows.join("\n");
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);

      const link = document.createElement("a");
      link.href = csvUrl;
      link.download = "data.csv";
      link.click();

      URL.revokeObjectURL(csvUrl);
    } else {
      const csvData =
        "Not Matched RX List" +
        "\n" +
        unmatchedReactions.map((element) => [element]).join("\n");
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);

      const link = document.createElement("a");
      link.href = csvUrl;
      link.download = `Not Matched RXs.csv`;
      link.click();

      URL.revokeObjectURL(csvUrl);
    }
  };

 
  //tablosayfalaması ile ilgili kısım
  const [rowsPerPage , setRowsPerPage ]= useState(10); // Her sayfada gösterilecek satır sayısı
  const [page, setPage] = useState(0);
  const totalItems = parsedData.length;

  const handleRowsPerPageChange = (e) => {
    const value = e.target.value === "All" ? totalItems : parseInt(e.target.value);
    setRowsPerPage(value);
    setPage(0); // Sayfa sıfırlansın
  };
  
  const startIndex = page * rowsPerPage;
  const endIndex = rowsPerPage === totalItems ? totalItems : startIndex + rowsPerPage;
  const displayedData = parsedData.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if ((page + 1) * rowsPerPage < parsedData.length) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  try {
    const parsedObject = typeof jsonData === "string" ? JSON.parse(jsonData) : jsonData;
    
    if (parsedObject.result_table_dict) {
      parsedData = parsedObject.result_table_dict.data || [];
      columnNames = parsedObject.result_table_dict.column_names || [];
    }

    if (parsedObject.not_matched_rx_lst) {
      unmatchedReactions = parsedObject.not_matched_rx_lst;
    }
  } catch (error) {
    console.error("Geçersiz JSON:", error);
    return <p>Hatalı JSON formatı</p>;
  }

  return (
    <div>
     
      <div style={{display:"flex" , justifyContent :"space-evenly" }}>
      <Button variant="contained" onClick={()=>setToggleUnmatchedNMatched(true)}>Sonuç tablosunu göster</Button>
      <Button variant="contained" onClick={()=>setToggleUnmatchedNMatched(false)}>Eşleşmeyenler tablosunu göster</Button>
      <Button
                variant="contained"
                onClick={handleDownloadCSV}
                style={{
                  marginLeft: "5px",
                }}
              >
                Download as CSV
              </Button>
      </div>

      <br />
     
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center"}}>
      <div>Input Reactions: {jsonData2[0] + jsonData2[1]}</div>
      <div>Matched Reactions: {jsonData2[0]}</div>
      <div>Unmatched Reactions: {jsonData2[1]}</div>

      </div>



      {toggleUnmatchedNMatched ? (
  // Sonuç Tablosu
  <div>
    <h3>Sonuç Tablosu</h3>
    {parsedData.length === 0 ? (
      <p>Veri bulunamadı</p>
    ) : (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnNames.map((item) => {
                if (item === "Matched Reactions" || item === "kegg_link") return null;
                return <TableCell key={crypto.randomUUID()}>{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedData.map((row, rowIndex) => (
              <TableRow
                key={crypto.randomUUID()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {row.map((cell, cellIndex) => {
                  const isMatchedReactions = columnNames[cellIndex] === "Matched Reactions";
                  const isPathwayName = columnNames[cellIndex] === "Pathway Name";
                  const isNumberOfMatches = columnNames[cellIndex] === "Number of Matches";
                  const isPValue = columnNames[cellIndex] === "p-val";
                  const isKegg_link = columnNames[cellIndex] === "kegg_link";

                  const isCorrectionMethod =
                    ["bonferroni", "sidak", "holm-sidak", "holm", "simes-hochberg", "fdr_bh", "fdr_by", "hommel", "fdr_tsbh", "fdr_tsbky"].includes(columnNames[cellIndex]);
                  const correctionLength = columnNames.length - 7;

                  if (isMatchedReactions || isKegg_link) return null;

                  if (isNumberOfMatches) {
                    return (
                      <TableCell key={crypto.randomUUID()}>
                        <Link href="" underline="hover" onClick={(event) => {
                          event.preventDefault();
                          handleShowTable(row[0]);
                        }}>
                          {cell}
                        </Link>
                      </TableCell>
                    );
                  }

                  if (isPathwayName) {
                    return (
                      <TableCell key={crypto.randomUUID()}>
                        <Link href={row[cellIndex + 4 + correctionLength]} target="_blank" underline="hover">{cell}</Link>
                      </TableCell>
                    );
                  }

                  if (isPValue || isCorrectionMethod) {
                    return <TableCell key={crypto.randomUUID()}>{toScientificNotation(cell)}</TableCell>;
                  }

                  return <TableCell key={crypto.randomUUID()}>{cell}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
      {/* Pagination */}
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25 , { label: "All", value: -1 }]}
          component="div"
          count={parsedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleRowsPerPageChange}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        /> */}
  </div>
) : (
  // Unmatched Reactions Bölümü
  <>
    <h1 style={{ marginTop: "20px" }}>Unmatched Reactions</h1>
    <TableContainer component={Paper} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Table sx={{ width: "200px" }}>
        <TableHead>
          <TableRow sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TableCell sx={{ width: "200px", textAlign: "center" }}>Unmatched Reactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody key={crypto.randomUUID()}>
          {unmatchedReactions.length === 0 ? (
            <TableRow sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TableCell>Eşleşmeyen Reaksiyon Yok</TableCell>
            </TableRow>
          ) : (
            unmatchedReactions.map((reaction) => (
              <TableRow key={crypto.randomUUID()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TableCell sx={{ width: "200px", textAlign: "center" }}>{reaction}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
)}


      
<Dialog
  open={showTable}
  onClose={handleShowTableClose} 
  sx={{
    '.MuiDialogPaper-root': {
      width: 'auto',  // İçeriğin genişliğine bağlı olarak ayar yapıyoruz
      maxWidth: 'none', // Genişlik sınırını kaldırıyoruz
    },
  }} 
  maxWidth="md"
>
  <DialogTitle>Matched Reactions</DialogTitle>
  <DialogContent>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>Matches</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedData.map((row, rowIndex) =>
            row.map((cell, cellIndex) =>
              columnNames[cellIndex] === "Matched Reactions" && row[0] == dialogId
                ? cell.map((item) => (
                    <TableRow key={crypto.randomUUID()}>
                      <TableCell sx={{ textAlign: "center" }}>{item}</TableCell>
                    </TableRow>
                  ))
                : null
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </DialogContent>
</Dialog>

    </div>
  );
};