import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage';
import Rseayeni from './Rseayeni';

function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/8eUo3hH6mJ5qXz1pWbNvQOV1R8Y5LKD0qGzHtA" element={<Rseayeni />} />
      </Routes>
    </Router>
  );
}

export default Main;
